import { ChangeEvent, useEffect, useState } from "react"
import { Card, Row } from "react-bootstrap"
import Switch from '@mui/material/Switch';
import { useNavigate } from "react-router-dom"
import Icon from "@mdi/react";
import { mdiTextBoxPlus } from "@mdi/js";
import axios from "axios";
import { toast } from "react-toastify";
import { getSessionData } from "../../../utils/storageUtils";
import ModalContagem from "./components/modalContagem";

function Inventario() {

    //Obtendo dados do local storage
    const token = getSessionData("MultfilialWebToken")
    const idFilial = getSessionData("DadosIdfilial")

    const [depositantes, setDepositantes] = useState<any>([])
    const [depositanteSelecionado, setDepositanteSelecionado] = useState<string>()

    //#region Contagem
    const [primeiraContagem, setPrimeiraContagem] = useState<boolean>()
    const [segundaContagem, setSegundaContagem] = useState<boolean>()
    const [recontar, setRecontar] = useState<boolean>()

    //#region Modo de Contagem 
    const [horizontal, setHorizontal] = useState<boolean>()
    const [vertical, setVertical] = useState<boolean>()
    const [impar, setImpar] = useState<boolean>()
    const [par, setPar] = useState<boolean>()
    const [seq, setSeq] = useState<boolean>(true)

    //#region Endereço
    const [armazens, setArmazens] = useState<any>([])
    const [corredores, setCorredores] = useState<any>([])
    const [armazemSelecionado, setArmazemSelecionado] = useState<string>('')
    const [corredorSelecionado, setCorredorSelecionado] = useState<string>('')

    //#region Infos contagem
    const [localContagem, setLocalContagem] = useState<string>('')
    const [contagem, setContagem] = useState<string>('')
    const [modoCont, setModoCont] = useState<string>('')
    const [ladoCont, setLadoCont] = useState<string>('Sequencial')
    const [idLocalContagem, setIdLocalContagem] = useState<number>(0)
    const [cnpj, setCnpj] = useState<string>('')
    
    const [nomeInventario, setNomeInventario] = useState<string>()
    const [cliente, setCliente] = useState<string>()
    const [mostraModalContagem, setMostraModalContagem] = useState<boolean>(false)

    const navigate = useNavigate()

    function buscaDepositantes() {
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/busca/depositantes?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then(function (resposta) {
            setDepositantes(resposta.data.data)
        }).catch(function (erro) {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setDepositanteSelecionado('')
                setDepositantes([])
                toast.error(erro.response.data.message)
            }
        })
    }

    async function carregaArmazem(cliente: string, nomeInventario: string) {
        if (!cliente || !nomeInventario) {
            return
        }

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/enderecos/${cliente}/${nomeInventario}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then((resposta) => {
            setArmazens(resposta.data.data)
        }).catch((erro) => {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setArmazens([])
                setArmazemSelecionado('')
                toast.error(erro.response.data.message)
            }
        })
    }

    async function carregaCorredor(armazem: string) {
        if (!armazem) {
            return
        }

        await axios.get(`${process.env.REACT_APP_API_BASE_URL}/carrega/corredor/${cliente}/${nomeInventario}/${armazem}?idFilial=${idFilial}`, {
            headers: {
                Authorization: token
            }
        }).then((resposta) => {
            setCorredores(resposta.data.data)

            if (resposta.data.data.length == 1) {
                setCorredorSelecionado(resposta.data.data[0].CORREDOR)
            }
        }).catch((erro) => {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                setCorredores([])
                setCorredorSelecionado("")
                toast.error(erro.response.data.message)
            }
        })
    }

    async function iniciarInventario() {
        if (!primeiraContagem && !segundaContagem && !recontar) {
            return toast.info("Selecione a contagem")
        } else if (!horizontal && !vertical) {
            return toast.info("Selecione os modos de contagem")
        } else if (!par && !impar && !seq) {
            return toast.info("Selecione o lado da contagem")
        } else if (!armazemSelecionado && !corredorSelecionado) {
            return toast.info("Selecione o endereço")
        }

        axios.put(`${process.env.REACT_APP_API_BASE_URL}/iniciar/inventario?idFilial=${idFilial}`, {
            primeiraContagem,
            segundaContagem,
            recontar,
            horizontal,
            vertical,
            par,
            impar,
            seq,
            armazemSelecionado,
            corredorSelecionado
        }, {
            headers: {
                Authorization: token
            }
        }).then((resposta) => {
            setLocalContagem(resposta.data.data)
            setIdLocalContagem(resposta.data.idLocal)
            setCnpj(resposta.data.CNPJ)
            setMostraModalContagem(true)
        }).catch((erro) => {
            const status = erro.response.status
            if (status == 403 || status == 401) {
                toast.error("Acesso negado, faça seu login novamente")
                navigate("/login")
            } else {
                toast.error(erro.response.data.message)
            }
        })
    }

    useEffect(() => {
        buscaDepositantes()
    }, [])

    return (
        <>

            <Card className="mt-2">
                <Card.Body>
                    <Row className="mt-1">
                        <div className="col form-floating">
                            <select
                                autoFocus
                                className="form-select bordasInferiorInput text-black text-capitalize"
                                onChange={(event) => {
                                    setDepositanteSelecionado(event.target.value)

                                    const itemSelecionado = depositantes.find((item: any) => item.Fantasia === event.target.value)

                                    const nome = itemSelecionado ? itemSelecionado.TITULO_INV : ''
                                    setNomeInventario(nome)

                                    const cliente = itemSelecionado ? itemSelecionado.CNPJ : ''
                                    setCliente(cliente)

                                    carregaArmazem(cliente, nome)
                                }}
                                value={depositanteSelecionado}
                            >
                                <option>Selecione</option>

                                {depositantes.map((depositante: any) => (
                                    <option key={depositante.CNPJ}>{depositante.Fantasia}</option>
                                ))}
                            </select>
                            <label className="form-label">DEPOSITANTE</label>
                        </div>
                    </Row>

                    <div className="container mt-3">
                        <fieldset className="p-2">
                            <legend className="w-auto px-2">Contagem</legend>
                            <Row>
                                <div className="col-4 form-check form-switch mt-2">
                                    <label htmlFor="primeiraContagem" className="form-label me-2" style={{ opacity: 0.9, fontSize: '0.7rem' }}>
                                        1º Cont
                                    </label>
                                    <input
                                        className="form-check-input ms-2"
                                        type="checkbox"
                                        checked={primeiraContagem}
                                        id="primeiraContagem"
                                        onChange={(e) => {
                                            setPrimeiraContagem(e.target.checked);
                                            if (e.target.checked) {
                                                setSegundaContagem(false);
                                                setRecontar(false);

                                                setContagem("1º Cont")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>
                                <div className="col-4 form-check form-switch  mt-2">
                                    <label htmlFor="segundaContagem" className="form-label me-2" style={{ opacity: 0.9, fontSize: '0.7rem' }}>
                                        2º Cont
                                    </label>
                                    <input
                                        className="form-check-input ms-2"
                                        type="checkbox"
                                        checked={segundaContagem}
                                        id="segundaContagem"
                                        onChange={(e) => {
                                            setSegundaContagem(e.target.checked);
                                            if (e.target.checked) {
                                                setPrimeiraContagem(false);
                                                setRecontar(false);

                                                setContagem("2º Cont")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>
                                <div className="col-4 form-check form-switch  mt-2">
                                    <label htmlFor="recontar" className="form-label" style={{ opacity: 0.9, fontSize: '0.7rem' }}>
                                        Recontar
                                    </label>
                                    <input
                                        className="form-check-input ms-2"
                                        type="checkbox"
                                        checked={recontar}
                                        id="recontar"
                                        onChange={(e) => {
                                            setRecontar(e.target.checked);
                                            if (e.target.checked) {
                                                setSegundaContagem(false);
                                                setPrimeiraContagem(false);

                                                setContagem("Recontagem")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>
                            </Row>


                        </fieldset>
                    </div>

                    <div className="container mt-2">
                        <fieldset className="p-2">
                            <legend className="w-auto px-2">Modo de contagem</legend>
                            <Row className="justify-content-between">
                                <div className="col-5 form-check form-switch mt-2">
                                    <label htmlFor="horizontal" className="form-label" style={{ opacity: 0.9, fontSize: '0.7rem' }}>
                                        Horizontal
                                    </label>
                                    <input
                                        className="form-check-input ms-2"
                                        type="checkbox"
                                        checked={horizontal}
                                        id="horizontal"
                                        onChange={(e) => {
                                            setHorizontal(e.target.checked);
                                            if (e.target.checked) {
                                                setVertical(false);

                                                setModoCont("Horizontal")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>
                                <div className="col-5 form-check form-switch mt-2 d-flex flex-column align-items-center">
                                    <label htmlFor="vertical" className="me-4 form-label" style={{ opacity: 0.9, fontSize: '0.7rem', marginBottom: '5px' }}>
                                        Vertical
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={vertical}
                                        id="vertical"
                                        onChange={(e) => {
                                            setVertical(e.target.checked);
                                            if (e.target.checked) {
                                                setHorizontal(false);

                                                setModoCont("Vertical")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>



                            </Row>


                            <Row>
                                <div className="col-4 form-check form-switch mt-2 d-flex flex-column align-items-center">
                                    <label htmlFor="par" className="me-4 form-label" style={{ opacity: 0.9, fontSize: '0.7rem', marginBottom: '5px' }}>
                                        Par
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={par}
                                        id="par"
                                        onChange={(e) => {
                                            setPar(e.target.checked);
                                            if (e.target.checked) {
                                                setImpar(false);
                                                setSeq(false);

                                                setLadoCont("Par")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>

                                <div className="col-4 form-check form-switch mt-2 d-flex flex-column align-items-center">
                                    <label htmlFor="impar" className="me-4 form-label" style={{ opacity: 0.9, fontSize: '0.7rem', marginBottom: '5px' }}>
                                        Ímpar
                                    </label>
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={impar}
                                        id="impar"
                                        onChange={(e) => {
                                            setImpar(e.target.checked);
                                            if (e.target.checked) {
                                                setPar(false);
                                                setSeq(false);

                                                setLadoCont("Impar")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>

                                <div className="col-4 form-check form-switch  mt-2">
                                    <label htmlFor="seq" className="form-label" style={{ opacity: 0.9, fontSize: '0.7rem' }}>
                                        Sequencial
                                    </label>
                                    <input
                                        className="form-check-input ms-2"
                                        type="checkbox"
                                        checked={seq}
                                        id="seq"
                                        onChange={(e) => {
                                            setSeq(e.target.checked);
                                            if (e.target.checked) {
                                                setPar(false);
                                                setImpar(false);

                                                setLadoCont("Sequencial")
                                            }
                                        }}
                                        style={{
                                            width: '35px'
                                        }}
                                    />
                                </div>
                            </Row>
                        </fieldset>
                    </div>

                    <div className="container mt-2">
                        <fieldset className="p-2">
                            <legend className="w-auto px-2">Endereço</legend>
                            <Row>
                                <div className="col-6 form-floating">
                                    <select
                                        autoFocus
                                        className="form-select bordasInferiorInput text-black text-capitalize"
                                        onChange={(event) => {
                                            setArmazemSelecionado(event.target.value)
                                            carregaCorredor(event.target.value)
                                        }}
                                        value={armazemSelecionado}
                                    >
                                        <option>Selecione</option>

                                        {armazens.map((armazem: any) => (
                                            <option>{armazem.CAMARA}</option>
                                        ))}
                                    </select>
                                    <label className="form-label">ARMAZÉM</label>
                                </div>

                                <div className="col-6 form-floating">
                                    <select
                                        autoFocus
                                        className="form-select bordasInferiorInput text-black text-capitalize"
                                        onChange={(event) => {
                                            setCorredorSelecionado(event.target.value)
                                        }}
                                        value={corredorSelecionado}
                                    >
                                        <option>Selecione</option>

                                        {corredores.map((cor: any) => (
                                            <option>{cor.CORREDOR}</option>
                                        ))}
                                    </select>
                                    <label className="form-label">CORREDOR</label>
                                </div>
                            </Row>
                        </fieldset>
                    </div>
                </Card.Body>
                <Card.Footer>
                    <div className="d-flex justify-content-end">
                        <button
                            type="button"
                            // hidden={btnIniciar}
                            className="w-50 btn btn-novo btn-block rounded"
                            onClick={iniciarInventario}
                        >
                            <span className='me-1'>Iniciar</span><Icon path={mdiTextBoxPlus} size={0.8} />
                        </button>
                    </div>
                </Card.Footer>
            </Card>

            <ModalContagem
                isOpen={mostraModalContagem}
                fecharModal={() => setMostraModalContagem(false)}
                localContagem={localContagem}
                contagem={contagem}
                modoCont={modoCont}
                ladoCont={ladoCont}
                idLocalContagem={idLocalContagem}
                cnpj={cnpj}
                armazem={armazemSelecionado}
                corredor={corredorSelecionado}
            />
        </>
    )
}

export default Inventario